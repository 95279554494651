import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import { UserRoles, Tools } from './common'

const profile = Tools.getProfile()
let currentRoles = [UserRoles.consumer]
if (profile && typeof profile.roles !== 'undefined' && profile.roles.length > 0) {
  currentRoles = profile.roles
}

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Customer Pages
  {
    path: '/overview',
    Component: loadable(() => import('pages/dashboard/overview')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/activity',
    Component: loadable(() => import('pages/dashboard/activity')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/balances',
    Component: loadable(() => import('pages/dashboard/balances')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/debit-cards',
    Component: loadable(() => import('pages/dashboard/debit-cards')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/history',
    Component: loadable(() => import('pages/dashboard/history')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/recipients',
    Component: loadable(() => import('pages/dashboard/recipients')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/invite',
    Component: loadable(() => import('pages/dashboard/invite')),
    exact: true,
    role: UserRoles.consumer,
  },
  {
    path: '/settings',
    Component: loadable(() => import('pages/dashboard/settings')),
    exact: true,
    role: UserRoles.consumer,
  },
  // Business Pages
  {
    path: '/home',
    Component: loadable(() => import('pages/dashboard/home')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/payments',
    Component: loadable(() => import('pages/dashboard/payments')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/balance',
    Component: loadable(() => import('pages/dashboard/balance')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/customers',
    Component: loadable(() => import('pages/dashboard/customers')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/reports',
    Component: loadable(() => import('pages/dashboard/reports')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/radar',
    Component: loadable(() => import('pages/dashboard/radar')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/billing',
    Component: loadable(() => import('pages/dashboard/billing')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/orders',
    Component: loadable(() => import('pages/dashboard/orders')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/connect',
    Component: loadable(() => import('pages/dashboard/connect')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/terminal',
    Component: loadable(() => import('pages/dashboard/terminal')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/developers',
    Component: loadable(() => import('pages/dashboard/developers')),
    exact: true,
    role: UserRoles.business,
  },
  {
    path: '/settings',
    Component: loadable(() => import('pages/dashboard/business-settings')),
    exact: true,
    role: UserRoles.business,
  },
  // System Pages
  {
    path: '/user/login',
    Component: loadable(() => import('pages/user/login')),
    exact: true,
    role: false,
  },
  {
    path: '/callback',
    Component: loadable(() => import('pages/system/callback')),
    exact: true,
    role: false,
  },
  {
    path: '/user/forgot-password',
    Component: loadable(() => import('pages/user/forgot-password')),
    exact: true,
    role: false,
  },
  {
    path: '/user/register',
    Component: loadable(() => import('pages/user/register')),
    exact: true,
    role: false,
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
    role: false,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
    role: false,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            {currentRoles.includes(UserRoles.consumer) && (
              <Route exact path="/" render={() => <Redirect to="/overview" />} />
            )}
            {currentRoles.includes(UserRoles.business) && (
              <Route exact path="/" render={() => <Redirect to="/home" />} />
            )}
            {routes.map(({ path, Component, exact, role }) => {
              if (role && !currentRoles.includes(role)) {
                return null
              }
              // console.log(path);
              return (
                <Route path={path} key={path} exact={exact} role={role}>
                  <Component />
                </Route>
              )
            })}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
