import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import auth0Client from './auth0Client'

const firebaseConfig = {
  apiKey: 'AIzaSyA2LHKgdr2GQb_QUBYfhMOaxgOuGjw1z5E',
  authDomain: 'airui-a4b63.firebaseapp.com',
  databaseURL: 'https://airui-a4b63.firebaseio.com',
  projectId: 'airui-a4b63',
  storageBucket: 'airui-a4b63.appspot.com',
  messagingSenderId: '1039460737420',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
// eslint-disable-next-line no-unused-vars
const firebaseAuth = firebase.auth
export default firebaseApp

export async function login(email, password) {
  return auth0Client.login({ email, password })
}

export async function currentAccount() {
  return auth0Client.getProfile()
}

export async function register(userInfo) {
  return auth0Client.register(userInfo)
}

export async function logout() {
  return auth0Client.signOut()
}

export async function resetPassword(values) {
  return auth0Client.resetPassword(values.email)
}
