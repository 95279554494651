const isDevServer =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

const Auth0Config = {
  // the following three lines MUST be updated
  domain: 'elektropay.auth0.com',
  // audience: 'https://<YOUR_AUTH0_DOMAIN>/userinfo',
  clientID: '7KqvUiHKFzV4nwWWdZXGg3XMdaH5za5Z',
  roleUrl: 'https://app.elektropay.com/roles',
  redirectUri: isDevServer
    ? 'http://localhost:3000/callback'
    : 'https://app.elektropay.com/callback',
  responseType: 'id_token',
  scope: 'openid profile email',
}
export default Auth0Config
