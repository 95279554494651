import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-7">
              <p>
                <span>&copy; 2019 Elektropay </span>
              </p>
            </div>
            <div className="col-md-5">
              <div className={style.logo}>
                <p>
                  <span>
                    <img
                      src="https://res.cloudinary.com/lmj6rf6tz/image/upload/c_scale,w_22/v1570069455/elektropay/assets/img/logo-64.png"
                      alt="I"
                    />
                  </span>
                  <span>
                    Privacy - Terms - Ssecurity - Status
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
