export async function getCustomerMenuData() {
  return [
    {
      category: true,
      title: '',
    },
    {
      title: 'Overview',
      key: 'overview',
      icon: 'fe fe-grid',
      url: '/overview',
    },
    // {
    //   category: true,
    //   title: '',
    // },
    {
      title: 'Activity',
      key: 'activity',
      icon: 'fe fe-align-left',
      url: '/activity',
    },
    {
      title: 'Balances',
      key: 'balances',
      icon: 'fe fe-layers',
      url: '/balances',
    },
    {
      title: 'Debit Cards',
      key: 'debit-cards',
      icon: 'fa fa-credit-card',
      url: '/debit-cards',
    },
    {
      title: 'History',
      key: 'history',
      icon: 'fa fa-history',
      url: '/history',
    },
    {
      title: 'Recipients',
      key: 'recipients',
      icon: 'fe fe-users',
      url: '/recipients',
    },
    {
      title: 'Invite',
      key: 'invite',
      icon: 'fe fe-mail',
      url: '/invite',
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fa fa-gear',
      url: '/settings',
    },
  ]
}

export async function getBusinessMenuData() {
  return [
    {
      category: true,
      title: '',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'fe fe-home',
      url: '/home',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Payments',
      key: 'payments',
      icon: 'fa fa-credit-card',
      url: '/payments',
    },
    {
      title: 'Balance',
      key: 'balance',
      icon: 'fe fe-layers',
      url: '/balance',
    },
    {
      title: 'Customers',
      key: 'customers',
      icon: 'fe fe-users',
      url: '/customers',
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'fa fa-indent',
      url: '/reports',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Radar',
      key: 'radar',
      icon: 'fa fa-bullseye',
      url: '/radar',
    },
    {
      title: 'Billing',
      key: 'billing',
      icon: 'fa fa-bank',
      url: '/billing',
    },
    {
      title: 'Connect',
      key: 'connect',
      icon: 'fe fe-link',
      url: '/connect',
    },
    {
      title: 'Orders',
      key: 'orders',
      icon: 'fa fa-opencart',
      url: '/orders',
    },
    {
      title: 'Terminal',
      key: 'terminal',
      icon: 'fa fa-terminal',
      url: '/terminal',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Developers',
      key: 'developers',
      icon: 'fa fa-connectdevelop',
      url: '/developers',
    },
    {
      category: true,
      title: '',
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fa fa-gear',
      url: '/settings',
    },
  ]
}
