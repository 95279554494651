import * as ls from 'local-storage'

export default class Tools {
  static getProfile() {
    let elProfile = ls.get('el_profile')
    if (elProfile) {
      elProfile = JSON.parse(elProfile)
      this.profile = elProfile
      return this.profile
    }
    return false
  }

  static getDomain() {
    try {
      const url = window.location.href
      const arr = url.split('/')
      return `${arr[0]}//${arr[2]}`
    } catch (e) {
      return window.location.hostname
    }
  }
}
