import auth0 from 'auth0-js'
import { notification } from 'antd'
import * as ls from 'local-storage'
import Auth0Config from './authConfig'

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth(Auth0Config)

    this.getProfile = this.getProfile.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signIn = this.signIn.bind(this)
    this.login = this.login.bind(this)
    this.register = this.register.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  getProfile() {
    let elProfile = ls.get('el_profile')
    if (elProfile) {
      elProfile = JSON.parse(elProfile)
      this.profile = elProfile
      return this.profile
    }
    return false
  }

  getIdToken() {
    return this.idToken
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt
  }

  signIn() {
    this.auth0.authorize()
  }

  // eslint-disable-next-line no-unused-vars
  login(authInfo) {
    this.auth0.login(
      {
        realm: 'Username-Password-Authentication',
        email: authInfo.email,
        password: authInfo.password,
      },
      err => {
        console.log('login err', err)
        if (err) {
          notification.warning({
            message: err.description,
            // description: err.description,
          })
          return false
        }
        return true
      },
    )
  }

  // eslint-disable-next-line no-unused-vars
  register(userInfo) {
    this.auth0.signup(
      {
        connection: 'Username-Password-Authentication',
        email: userInfo.email,
        password: userInfo.password,
        user_metadata: { fullname: userInfo.fullname },
      },
      err => {
        if (err) {
          console.log('register err', err)
          notification.warning({
            message: err.description ? err.description : err.original.response.body.message,
            description: err.policy ? err.policy : '',
            duration: 5,
          })
          return false
        }
        notification.success({
          message: 'Registerd',
          description: 'You have successfully registered in to Air UI React Admin Template!',
        })
        setTimeout(() => {
          window.location.href = '/user/login'
        }, 1000)
        return true
      },
    )
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line consistent-return
      this.auth0.parseHash((err, authResult) => {
        console.log('handleAuthentication err', err)
        console.log('handleAuthentication authResult', authResult)
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        this.idToken = authResult.idToken
        this.profile = authResult.idTokenPayload
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000
        this.profile.roles = this.profile[Auth0Config.roleUrl]
        ls.set('el_profile', JSON.stringify(this.profile))
        ls.set('el_idToken', this.idToken)
        ls.set('el_expiresAt', this.expiresAt)
        resolve(authResult)
      })
    })
  }

  signOut() {
    // clear id token, profile, and expiration
    this.idToken = null
    this.profile = null
    this.expiresAt = null
    ls.remove('el_profile')
    ls.remove('el_idToken')
    ls.remove('el_expiresAt')
    return true
  }

  resetPassword(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, resp) => {
          if (err) {
            reject(err)
          } else {
            resolve(resp)
          }
        },
      )
    })
  }
}

const auth0Client = new Auth()

export default auth0Client
